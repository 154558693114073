import React from 'react';
import {Space, Table, Tag} from "antd";
interface DataType {
    key: string;
    usluga: string;
    rad13_15: string;
    rad16:string;
    rad17:string;
    rad18:string;
    rad19:string;
    rad20:string;
    rad21:string;
    rad22:string;
}

// @ts-ignore
const columns: ColumnsType<DataType> = [
    {
        title:"Услуги",
        dataIndex:"usluga"
    },
    {
        title: "R13-R15",
        dataIndex: "rad13_15"
    },
    {
        title: "R16",
        dataIndex: "rad16"
    },{
        title: "R17",
        dataIndex: "rad17"
    },{
        title: "R18",
        dataIndex: "rad18"
    },{
        title: "R19",
        dataIndex: "rad19"
    },{
        title: "R20",
        dataIndex: "rad20"
    },{
        title: "R21",
        dataIndex: "rad21"
    },{
        title: "R22",
        dataIndex: "rad22"
    },
];

const Data:DataType[]=[{
    key:"1",
    usluga:"Комплекс 4-х колес",
    rad13_15:"2000руб",
    rad16:"2200руб",
    rad17:"2400руб",
    rad18:"2800руб",
    rad19:"3200руб",
    rad20:"3400руб",
    rad21:"3600руб",
    rad22:"3800руб",
},{
    key:"1",
    usluga:" Снятие/установка 1-го колеса",
    rad13_15:"150руб",
    rad16:"200руб",
    rad17:"200руб",
    rad18:"250руб",
    rad19:"300руб",
    rad20:"300руб",
    rad21:"300руб",
    rad22:"300руб",
},
    {
        key:"1",
        usluga:"Балансировка 1-го колеса",
        rad13_15:"150руб",
        rad16:"200руб",
        rad17:"200руб",
        rad18:"250руб",
        rad19:"300руб",
        rad20:"400руб",
        rad21:"400руб",
        rad22:"400руб",
    },{
        key:"1",
        usluga:"Хранение одной шины/диска в день",
        rad13_15:"50руб",
        rad16:"50руб",
        rad17:"50руб",
        rad18:"50руб",
        rad19:"50руб",
        rad20:"50руб",
        rad21:"50руб",
        rad22:"50руб",
    },]
const PriceShin = () => {
    return (
            <div>
                <h1 style={{textAlign:"center",fontSize:"22px",margin:"20px"}}>Шиномонтаж</h1>
                <Table pagination={false} scroll={{x:true}} columns={columns} dataSource={Data} />
            </div>

    );
};

export default PriceShin;