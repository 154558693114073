import React, {useEffect} from 'react';
import "../Shinka/shin.css"

const Komkhim = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/71b587642c3d98d5cf260ac5e45d569d.png"
                             alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Комплексная химчистка автомобиля</h3>
                        <p className="shin__text">
                            Каждый стремится, чтобы его автомобиль был чистым внутри и снаружи. Внешняя часть пачкается
                            быстрее, но процесс очистки не требует особых усилий чего нельзя сказать о салоне.
                            Периодически владелец может сталкиваться с необходимостью очистки внутренней части машины.
                            Комплексная химчистка автомобиля – услуга, включающая химчистку всего съемного оборудования.
                            В независимости от вида чистки данный процесс должен выполняться с использованием
                            профессионального оборудования и соответствующих средств. Сервисный центр «Кохстудия»
                            предоставляет данную услугу по весьма доступной цене.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Когда может понадобиться комплексная химчистка?</h3>
                        <p className="shin__text">
                            Мировые детейлеры советуют обращаться к специалистам не менее 2-3 раза в год в зависимости от активности эксплуатации. Зачастую комплексная чистка автомобиля необходима если:
                            <br/><br/>
                            машина используется в коммерческих целях;<br/>
                            в салоне часто курят;<br/>
                            был приобретен подержанный автомобиль с большим пробегом;<br/>
                            у автовладельца есть маленькие дети;<br/>
                            было принято решение продавать машину и т. д.<br/>
                            <br/> Часто к химчистке многие относятся как к эстетической процедуре. Но, после выполнения чистки пребывать в машине будет не просто приятнее, но и безопаснее. К тому же после процедуры все системы будут работать намного эффективнее, особенно это касается кондиционера.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Какие этапы включает чистка</h3>
                        <p className="shin__text">
                            Полная химчистка автомобиля затрагивает все труднодоступные места. Мастер разбирает салон,
                            поднимает покрытие ковров, убирает накладки. Процедура осуществляется с помощью
                            специализированных пылесосов с разными насадками и не только.
                            <br/>
                            Выполняется отдельная мойка каждого элемента салона. При этом применяемые средства для
                            кожаного и текстильного салона будут отличаться. В процессе работы используются только
                            гипоаллергенные и антибактериальные средства.<br/>
                            Комплексная чистка авто может занимать несколько часов. Специалистами будут устранены все
                            виды загрязнений. На завершающем этапе осуществляется обработка поверхностей разными видами
                            полиролей, что придаст дополнительной свежести салону.
                        </p>
                    </div>
                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Преимущества профессионального комплексного решения</h3>
                        <p className="shin__text">
                            Комплексная химчистка машины и детейлинг поможет избавить от грязи все поверхности. После
                            проведения процедуры исчезнут:<br/>
                            <br/><ul className="shin__ul">
                                <li>видимые и невидимые пятна;</li>
                                <li>смолы, деготь и другие смолистые вещества;</li>
                                <li>шерстяные отложения, волосы людей, волоконная пыль</li>
                                <li>жировые пятна.</li>
                            </ul><br/>
                            Полная химчистка автомобиля по доступной цене в городе Москва будет проведена с применением
                            техники от ведущих мировых производителей. В своей работе мы используем только
                            профессиональную химию. Позаботьтесь о своей машине прямо сейчас, заказывайте комплексную
                            чистку в удобное для вас время.


                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Komkhim;