import React, {useEffect} from 'react';
import "../uslugi.css"
import {NavLink} from "react-router-dom";

const Polka = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className="uslugibox animate__fadeInLeft">
            <div className="uslugibox__inner">
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/20a7cbc60cda98cf807fdd93e709c273.jpg"
                             alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Полировка автомобиля</h3>
                        <p className="uslugibox__text"></p>
                        <NavLink to="polirovka" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://avatars.mds.yandex.net/i?id=9acb4c24824750a5eff6895ce20cc98e294088c6-9068811-images-thumbs&n=13"
                             alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Полировка фар</h3>
                        <p className="uslugibox__text"></p>
                        <NavLink to="polfar" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Polka;