import React, {useEffect} from 'react';
import "../uslugi.css"
import {NavLink} from "react-router-dom";

const Khimka = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    return (
        <div className="uslugibox animate__fadeInLeft">
            <div className="uslugibox__inner">
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/71b587642c3d98d5cf260ac5e45d569d.png" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Комплексная химчистка автомобиля</h3>
                        <p className="uslugibox__text">
                            Каждый стремится, чтобы его автомобиль был чистым внутри и снаружи. Внешняя часть пачкается быстрее, но процесс очистки не требует особых усилий чего нельзя сказать о салоне. Периодически владелец может сталкиваться с необходимостью очистки внутренней части машины.</p>
                        <NavLink to="komkhim" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/b72d106f76d0f75ebb12e46be8b35429.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Химчистка салона автомобиля</h3>
                        <p className="uslugibox__text">
                            Интенсивная эксплуатация автомобиля, курение, транспортировка различных грузов может привести к необходимости выполнить очистку кресел и всего салона машины. Даже при аккуратном пользовании и соответствующем уходе спинка кресла способна покрываться грязью. Со временем все это может превратиться в среду для развития грибков и бактерий. Эффективно со всем этим сумеют справиться только профессионалы.</p>
                        <NavLink to="khim" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/61c22a64ce5debb8a97c5c8223e271c3.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">
                            Химчистка сидений
                        </h3>
                        <p className="uslugibox__text">
                            Интенсивная эксплуатация автомобиля, курение, транспортировка различных грузов может привести к необходимости выполнить очистку кресел и всего салона машины. Даже при аккуратном пользовании и соответствующем уходе спинка кресла способна покрываться грязью. Со временем все это может превратиться в среду для развития грибков и бактерий. Эффективно со всем этим сумеют справиться только профессионалы.

                        </p>
                        <NavLink to="khimsid" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/3d91eba8439de79ff563a15447f3d40b.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Химчистка пола автомобиля</h3>
                        <p className="uslugibox__text">
                            Надлежащий уход за автомобилем не ограничивается только мытьем внешней поверхности. Со временем салон теряет свежесть, и ездить на таком транспортном средстве некомфортно и неприятно. Помимо эстетики необходимо позаботиться и о внутренней части салона, в том числе о чистоте пола. Мастера сервисного центра «Кохстудия» с помощью профессионального оборудования выполнят эффективную чистку автомобиля.
                        </p>
                        <NavLink to="khimpola" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Khimka;