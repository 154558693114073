import React, {useEffect} from 'react';
import "./Shinka/shin.css"

const Zashita = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src={"https://acover.ru/wp-content/uploads/2022/03/2864cf31696fe5f677a805ea40d6275e.jpg"}                        alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Жидкое стекло WILLSON</h3>
                        <p className="shin__text">
                            Жидкое стекло для автомобиля представляет собой средство, которым можно защитить не только кузов автомобиля, но еще и фары. Оно отлично защищает авто от различного рода воздействий окружающей среды. Во время дождя на кузове не оставляется никаких дополнительных разводов.
                            Преимущества обработки автомобиля именно жидким стеклом:
                            <br/>
                            <br/>Средства отличаются своей дешевизной по сравнению с другими видами полировки;
                            <br/>При использовании появляется насыщенный цвет и блеск;
                            <br/>Данный вид полировки очень стойкий к воздействиям окружающей среды;
                            <br/>После очередной мойки микроцарапины будут практически не видны;

                            <br/><br/>
                            Если автомобиль становиться тусклее, то его легко обновить с помощью покрытие авто жидким стеклом.
                            Жидкое стекло компании Willson. У каждого автолюбителя всегда есть желание сделать свой автомобиль еще более привлекательным, чем он есть на данный момент. При использовании лакокрасочное покрытие со временем теряет свой оттенок, начинает лопаться, появляются различного рода повреждения, которые портят вид машины. Как раз для таких проблем и предназначено средство Willson.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Особенности данной продукции</h3>
                        <p className="shin__text">
                            Жидкое стекло для автомобиля представляет собой средство, которым можно защитить не только кузов автомобиля, но еще и фары. Оно отлично защищает авто от различного рода воздействий окружающей среды. Во время дождя на кузове не оставляется никаких дополнительных разводов.

                            Самому лучше не проделывать такую полировку, так как перед началом нужно знать, какие процедуры могут потребоваться для действительно хорошей обработки. Чтобы не испортить свой автомобиль, рекомендуется обращаться только к квалифицированным компаниям. Только они смогут полностью изменить ваше транспортное средство и дать гарантию на свою работу.

                            Благодаря своим свойствам, полироль позволяет полностью защитить кузов авто, сохранить родной цвет и интенсивность и предать машине свой блеск. Средство также на длительное время защищает от появления пятен и микроцарапин.

                            Если вы решили сделать покрытие автомобиля жидким стеклом, цена будет не маленькая, но это будет того стоить. Качественные услуги, выполненные профессионалами заметно преобразят ваше авто до его первоначального состояния. Поверьте, все ваши знакомые будут считать, что вы приобрели себе новое транспортное средство.

                            Будьте осторожны! Все ажиотажное молниеносно подделывается. Чтобы получить действительно ожидаемый результат, а не очередное разочарование, остерегайтесь мошенников, предлагающих инновационное японское «жидкое стекло» по цене ниже закупочной в Японии.
                            Рекомендуемая производителем Willson розничная цена на упаковку (объём 95 мл) для внутреннего рынка Японии — 5200 йен, что равно 4053 рублям! Поэтому оригинальное «жидкое стекло» никак не может стоить 1200 или 1900 руб., то есть на 30-50% дешевле, чем на официальном сайте бренда и в Японии
                            <a
                            href="http://www.willson.co.jp/products/carwax/02/#Anc123">(ссылка на стр. товара на сайте производителя).</a>

                            Мы закупаем продукт оптовыми партиями напрямую с завода Willson, что позволяет нам предлагать лучшую для вас цену на оригинал
                        </p>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default Zashita;