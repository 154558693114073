import React, {useEffect} from 'react';
import "../Shinka/shin.css"

const Khim = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://avatars.mds.yandex.net/i?id=9acb4c24824750a5eff6895ce20cc98e294088c6-9068811-images-thumbs&n=13"
                             alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Полировка фар</h3>
                        <p className="shin__text">
                            <h2 style={{fontSize:"22px"}}>Ваши фары будут как новые.</h2>
                            Сохранить идеальное состояние транспортного средства достаточно сложно, однако при ведении регулярного ухода, продлить эксплуатационный срок любимого автомобиля вполне реально. Особое внимание следует уделять фарам, ведь от этого элемента авто зависит качество освещения, необходимого во время движения в темное время суток.

                            Наша компания предоставляет профессиональную услугу для устранения негативных дефектов на корпусе фары, исключает потускнение основы и налаживает эффективное освещение данных приборов, за весьма доступную оплату.

                            Профессиональная полировка фар автомобиля осуществляется поэтапно, с применением специальных инструментов и полировальных средств для качественной очистки. На первом этапе производятся подготовительные работы, предусматривающие тщательное очищение поверхности прибора, для устранения жирности и пыльных скоплений. После очистки, производится высушивание фары, а далее, специалист приступает к демонтажу радиаторной решетки и поворотников, для исключения их повреждения в процессе проведения полировочных работ.

                            Следующий шаг - абразивная полировка фар, с применением грубого и мелкого абразива. Данный материал используется в определенном времени (не более 3-4 минут), с обязательным смачиванием, для сохранения эстетичных свойств изделия. После каждого этапа очистки, производится смывание образованной стружки. Завершительный этап предусматривает проведение абразивной шлифовки, нанесение полировки до обретения блеска, тщательное вымывание и просушку восстановленного элемента.

                            Полировка фар авто позволяет не только восстановить идеальный вид осветительного прибора, но и устранить неприятные склоки и царапины на фарах, заметно ухудшающие внешний вид и качество освещения. Помимо полировки фар, мы также готовы произвести профессиональную очистку стекол авто, с применением качественных составов. Результат проведенных работ – идеальный вид стекол и фар, преобразующихся до состояния новых изделий.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default Khim;