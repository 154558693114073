import React, {useEffect} from 'react';
import "./Shinka/shin.css"

const Ozonat = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src={"https://acover.ru/wp-content/uploads/2022/03/3.jpg"}                        alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Озонация салона автомобиля</h3>
                        <p className="shin__text">
                            Озонирование салона автомобиля – процедура, которая избавляет салон вашего авто от лишних запахов и эффективно дезинфицирует воздух устраняя вредные химические вещества. В домашних условиях восстановить свежесть в микроклимате салона или кабины нереально, поскольку требуется особое профессиональное оборудование, а нередко и предварительная химчистка.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Специфика устранения запахов в салоне автомобиля</h3>
                        <p className="shin__text">
                            Удаление запаха в салоне автомобиля требует особого ионизатора. В процессе устранения запаха в машине наш специалист может восстановить свежесть в салоне авто комплексно, в том числе путем избавления от всевозможных загрязнителей и обработки:

                            поверхностей передней панели, дверей, сидений, подлокотников и других предметов автомобильного интерьера;
                            внутренней обивки
                            потолка с любым покрытием и любой конструкцией крыши;
                            напольного покрытия – съемного и несъемного;
                            ниш, съемных и несъемных емкостей и креплений для монтажа компьютера, экрана ТВ и холодильника.
                            Выбирая исполнителя по озоновой чистке салона, можете смело довериться нам. Наши специалисты имеют богатый опыт и прошли курс обучения под руководством специалистов, сертифицированных производителями техники.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Причины и преимущества озонирования автомобильных салонов</h3>
                        <p className="shin__text">
                            Озонирование салона рекомендуется проводить один - два раза в год или по мере необходимости. Вы можете заказать его отдельно или в комплексе с химчисткой или другими услугами нашей студии. Ведь атмосфера в салоне или кабине ухудшается за полгода-год, поскольку застаиваются запахи:

                            табачного дегтя по причине регулярного курения водителя или пассажиров;
                            остатков пищи (если в машине кушают периодически или регулярно);
                            разлагающейся органики (если в салоне прижились бактерии);
                            сырости (из-за колоний микро-грибка на остатках органики);
                            животного (если перевозятся домашние питомцы).
                            Для того, чтобы убрать запах в машине понадобится около часа времени. Так же немного времени вы потратите на доставку автомобиля к нам на обслуживание.

                            Заказать озонирование салона авто с гарантией 100%-го качества вы можете по телефону. Предварительные заявки принимаются и через форму отправки заказа на сайте. Перед выполнением заказа, наш специалист детально обсудит с вами условия восстановления свежести в салоне. Возможно, понадобился предварительная комплексная уборка или хотя бы чистка моющим пылесосом. Стоимость услуги зависит от объема работ, при этом, качество будет высоким при любом количестве операций.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default Ozonat;