import React, {useEffect} from 'react';
import "../Shinka/shin.css"

const KhimSid = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/61c22a64ce5debb8a97c5c8223e271c3.jpg"
                             alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Химчистка сидений</h3>
                        <p className="shin__text">
                            Интенсивная эксплуатация автомобиля, курение, транспортировка различных грузов может
                            привести к необходимости выполнить очистку кресел и всего салона машины. Даже при аккуратном
                            пользовании и соответствующем уходе спинка кресла способна покрываться грязью. Со временем
                            все это может превратиться в среду для развития грибков и бактерий. Эффективно со всем этим
                            сумеют справиться только профессионалы.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Причины, по которым может понадобиться услуга</h3>
                        <p className="shin__text">
                            Химчистка сидений автомобиля позволяет избавиться от разных загрязнений, которые невозможно
                            устранить обычным пылесосом. Подобная процедура устраняет неприятные запахи и предотвращает
                            возникновение аллергических реакций. Многие автовладельцы предполагают, что данная услуга
                            необходима только перед продажей авто, но это не так. Химчистка сидений машины выполняется в
                            следующих случаях:

                            кожаные кресла блестят;
                            в салоне появился неприятный запах;
                            появились разводы и пятна;
                            недавно был приобретен б/у автомобиль и т.д.
                            Если самостоятельно устранить загрязнения не получается, то лучшим решением будет обратиться
                            за помощью к специалистам. Чистка сидений автомобиля в Москве будет выполнена с применением
                            профессионального оборудования и соответствующих моющих средств. Чтобы салон автомобиля
                            всегда выглядел ухоженным, рекомендуется обращаться в детейлинг центр 2-3 раза в год.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Профессиональные услуги химчистки
                        </h3>
                        <p className="shin__text">

                            Сервисный центр «Кохстудия» существует на рынке более 5 лет. Мы гарантируем ликвидацию даже
                            трудных пятен, разводов и труднодоступных загрязнений. Химчистка сидений в Москве будет
                            выполнена:

                            с применением профессиональной техники и средств;
                            оперативно по доступной цене;
                            с использованием только безопасных средств.
                            Химчистка сидений выполняется поэтапно. Недопустимым является использование парогенераторов,
                            если чехол изготовлен из кожи. Тканевые кресла обрабатываются качественными химикатами,
                            которые затем удаляются с помощью специального пылесоса. После завершения чистки следы
                            чистящих средств распадаются не оставляя даже намека на химический запах.

                            Полноценная химчистка сидений авто может длиться от 8 до 12 часов. Цена услуги напрямую
                            зависит от степени загрязнения, материала обшивки, затраченного времени, размера авто.
                            Заказать услугу можно оставив заявку у нас на сайте или любым другим удобным способом. Если
                            же у вас возникли вопросы, звоните к нам и наш менеджер проконсультирует вас.

                        </p>
                    </div>
                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Почему необходимо обращаться за помощью к профессионалам?</h3>
                        <p className="shin__text">
                            Безусловно, обратиться за помощью к профессионалам станет лучшим решением. Детейлинг центр
                            «Кохстудия» уже более 8 лет существует на рынке. Наши мастера качественно и профессионально
                            выполнят химчистку пола автомобиля по доступной цене в Москве. Мы учитываем особенности
                            каждого случая и всегда подходим к работе со знанием дела.

                            Цена на услугу химчистка пола автомобиля зависит от сложности загрязнения, объема работ,
                            использованных расходных материалов и других параметров. Более подробную информацию о
                            стоимости вы можете узнать во время консультации. У вас возникли дополнительные вопросы?
                            Оставляйте свою заявку на сайте или свяжитесь с нашим менеджером другим удобным для вас
                            способом.

                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default KhimSid;