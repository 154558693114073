import React from 'react';
import "animate.css"
import './App.css';
import Header from "./Component/Header/Header";
import Uslugi from "./Component/Uslugi/Uslugi";
import Footer from "./Component/Footer/Footer";
import Shinka from "./Component/Uslugi/Shinka/Shinka";
import Khimka from "./Component/Uslugi/Khimka/Khimka";
import {Route, Routes} from "react-router-dom";
import Shin from "./Component/Uslugi/Shinka/Shin";
import Hranenie from "./Component/Uslugi/Shinka/Hranenie";
import Balance from "./Component/Uslugi/Shinka/Balance";
import Reblesment from "./Component/Uslugi/Shinka/Reblesment";
import Komkhim from "./Component/Uslugi/Khimka/komkhim";
import Khim from "./Component/Uslugi/Khimka/Khim";
import KhimPola from "./Component/Uslugi/Khimka/KhimPola";
import KhimSid from "./Component/Uslugi/Khimka/KhimSid";
import Polka from "./Component/Uslugi/Polka/Polka";
import Polirovka from "./Component/Uslugi/Polka/Polirovka";
import Polfar from "./Component/Uslugi/Polka/polfar";
import Predprodazha from "./Component/Uslugi/Predprodazha";
import Zashita from "./Component/Uslugi/Zashita";
import Ozonat from "./Component/Uslugi/Ozonat";
import Price from "./Component/Price/Price";

function App() {
  return (
    <div className="App">
        <Header/>
        <Routes>
            <Route path="/"   element={<div className="animate__animated animate__fadeInLeft"><Uslugi/></div>}/>
            <Route path="/price"   element={<div className="animate__animated animate__fadeInLeft"><Price/></div>}/>
            <Route path="/predprodazha"   element={<div className="animate__animated animate__fadeInLeft"><Predprodazha/></div>}/>
            <Route path="/zashita"   element={<div className="animate__animated animate__fadeInLeft"><Zashita/></div>}/>
            <Route path="/ozonat"   element={<div className="animate__animated animate__fadeInLeft"><Ozonat/></div>}/>
            <Route path="/shinka" element={<div className="animate__animated animate__fadeInLeft"><Shinka/></div>}/>
            <Route path="/khimka" element={<div className="animate__animated animate__fadeInLeft"><Khimka/></div>}/>
            <Route path="/polka" element={<div className="animate__animated animate__fadeInLeft"><Polka/></div>}/>
            <Route path="/polka/polirovka" element={<div className="animate__animated animate__fadeInLeft"><Polirovka/></div>}/>
            <Route path="/polka/polfar" element={<div className="animate__animated animate__fadeInLeft"><Polfar/></div>}/>
            <Route path="/khimka/komkhim" element={<div className="animate__animated animate__fadeInLeft"><Komkhim/></div>}/>
            <Route path="/khimka/khimpola" element={<div className="animate__animated animate__fadeInLeft"><KhimPola/></div>}/>
            <Route path="/khimka/khimsid" element={<div className="animate__animated animate__fadeInLeft"><KhimSid/></div>}/>
            <Route path="/khimka/khim" element={<div className="animate__animated animate__fadeInLeft"><Khim/></div>}/>
            <Route path="shinka/shin" element={<div className="animate__animated animate__fadeInLeft"><Shin/></div>}/>
            <Route path="shinka/hranenie" element={<div className="animate__animated animate__fadeInLeft"><Hranenie/></div>}/>
            <Route path="shinka/balance" element={<div className="animate__animated animate__fadeInLeft"><Balance/></div>}/>
            <Route path="shinka/reblesment" element={<div className="animate__animated animate__fadeInLeft"><Reblesment/></div>}/>
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;
