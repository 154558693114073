import React, {useEffect} from 'react';
import "./shin.css"

const Hranenie = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/Screenshot_1.jpg" alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Хранение шин</h3>
                        <p className="shin__text">
                            Дважды в год автовладелец сталкивается с необходимостью замены резины. При этом сложности могут возникать не только с самой процедурой, но и с поиском места для хранения. Сразу может возникнуть вопрос, а что делать со свободным комплектом?
                            Автосервис  «Кохстудия» предлагает обратить внимание на услугу сезонное хранение шин в Москве. Мы готовы создать все необходимые условия. Соблюдение стандартов значительно увеличит эксплуатационный срок резины, сохранит технические характеристики и защитит от деформации.



                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Особенности услуги</h3>
                        <p className="shin__text">
                            Сезонное консервирование покрышек – не роскошь, а необходимость. Мы тщательно следим за соблюдением всех правил. Стоит отметить, что колеса будут помещены в специальное помещение с поддержанием необходимых условий. Здесь соблюдается оптимальная температура и соответствующий уровень влажности. А еще отсутствует прямое попадание  солнечных лучей.
                             <br/>
                            Дополнительно хранение шин предусматривает выполнение следующих параметров:
                            <br/><br/>
                            покрышки не складываются друг на друга;<br/>
                            колеса помещаются в специализированные чехлы, предотвращающие деформацию;<br/>
                            покрышки отправляют на консервацию в спущенном состоянии;<br/>
                            комплект размещается в стоящем положении.<br/>
                            Мы предлагаем услуги хранения шин в комплекте с дисками, с предварительной консервацией или без дисков. Обращаясь в наш автосервис, вы можете дополнительно рассчитывать на: тщательную мойку резины, сушку, очистку дисков, нанесение специальных растворов для консервации, накрытие пленкой и т.д.<br/>
                            <br/><br/>
                            Хранение автомобильных шин у нас предусматривает выдачу соответствующего договора, подтверждающего факт передачи. С его помощью закрепляются основные положения взаимных прав и обязанностей. Забрать свою собственность вы сможете в любе время.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Стоимость услуги</h3>
                        <p className="shin__text">
                            Наш сервис предлагает сезонное хранение шин в Москве недорого с учетом всех требований. Соблюдение технологий позволяет нам гарантировать сохранность. Цена на хранение шин в Москве зависит от:

                            <br/> типа автомобиля  (легковой или грузовой);<br/>
                            колесо отправляется на консервацию с диском или без него;<br/>
                            необходимости в осуществлении предварительной консервации.<br/>
                            После уточнения параметров специалистами будет оговорена стоимость услуги. При согласовании всех условий оформляется договор. Колеса в короткие сроки маркируются и помещаются на специальный модуль.  Благодаря этому, по окончанию сезона вы сможете получить покрышки в том состоянии, в котором они поступили на склад.
                            <br/>
                            Шины будут храниться в чистом помещении и вам больше не придется освобождать пространство на балконе, в коридоре или гараже. Помните, что соблюдение стандартов – залог вашей безопасности на дороге! Поэтому выбирайте только профессионалов, обращайтесь в сервис «Кохстудия».
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Hranenie;