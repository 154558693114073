import React, {useEffect} from 'react';
import "./shin.css"

const Shin = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/6202df3da32770b023dcd4a9558ef6f4.jpg" alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Шиномонтаж</h3>
                        <p className="shin__text">
                            Дорожное покрытие оставляет желать лучшего, и как бы не старался водитель избежать этого, но к услугам автосервиса приходиться прибегать неоднократно. Как минимум дважды в год автовладелец сталкивается с заменой резины – весной и осенью.
                            Компания «Кохстудия» более 8 лет оказывает услуги шиномонтажа. Наши специалисты работают практически с любым типом колес. Благодаря наличию немецкого оборудования мы готовы удовлетворять любые потребности наших клиентов.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Какой перечень работ выполняют наши сотрудники?</h3>
                        <p className="shin__text">
                            Шиномонтажные работы способствуют улучшению балансировки покрышек и дисков, от которых напрямую зависит функционирование ходовой части автомобиля. Важно отметить, что при самостоятельном выполнении данного процесса могут быть допущены ошибки, которые способны привести к негативным последствиям.

                            <b>.Если вы не уверены в своих силах, обращайтесь в автосервис шиномонтаж в городе Москва «Кохстудия». Наши специалисты готовы выполнить:</b><br/>
                            <br/><br/>замену и снятие колес;<br/>
                            диагностику давления в шинах;<br/>
                            монтаж шин;<br/>
                            балансировку колес и т.д.<br/> <br/>
                            В своей работе мы используем современное оборудование Trommelberg. Каждый пост имеет по 3 специалиста, которые отвечает за определенный этап (установка или съемка колес, балансировка, пересортировка резины на диске). Это позволяет нам осуществлять быстрое и качественное обслуживание.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Когда нужны услуги шиномонтажа?</h3>
                        <p className="shin__text">
                            Оказание услуг шиномонтажа нашим сервисом выполняется в несколько этапов и включает дополнительные процедуры. Это не просто процесс по замене покрышек, а целый комплекс процедур. Но, когда автовладелец осуществляет поиск по запросу «шиномонтаж недорого Москва», то зачастую это происходит: из-за повреждения резины, необходимости в сезонной замене, несоответствии шин параметрам производителя.

                            <br/><br/>Автовладельцы могут обращаться в автосервис и для осуществления ремонта покрышек, балансировки колес и не только. Стоит отметить, что подобные процедуры должны выполняться профессионалами с применением качественных расходных материалов. Наши сотрудники имеют многолетний стаж, что позволяет брать в работу сложные случаи.
                        </p>
                    </div><div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Профессиональные услуги шиномонтажа в Москве</h3>
                        <p className="shin__text">
                            Сервисный центр «Кохстудия» готов похвастаться профессиональным оборудованием и качественным сервисом. Диагностика и ремонт выполняются обученными мастерами с наличием необходимого багажа знаний. Сотрудничество с нами имеет ряд преимуществ, среди которых:

                            <br/> <br/>шиномонтаж в Москве по доступной стоимости; <br/>
                            оперативное выполнение всех ремонтных работ; <br/>
                            осуществление работы с колесами разных размеров; <br/>
                            предоставление гарантии; <br/>
                            использование только сертифицированных расходных материалов. <br/>
                            Помните, что обращение к мастерам, обеспечит вам безопасность на дорогах! Не тратьте времени на поиск профессионального автосервиса. Обращайтесь к нам, и мы предоставим вам услуги по доступной цене. <br/>
                        </p>
                    </div>
                </div>
                </div>
            </div>

        </div>
    );
};

export default Shin;