import React from 'react';
import {Space, Table, Tag} from "antd";

// @ts-ignore
const columns: ColumnsType<DataType> = [
    {
        title:"Полировка кузова",
        dataIndex:"usluga"
    },
    {
        title: "I категория",
        dataIndex: "one"
    },
    {
        title: " II категория",
        dataIndex: "two"
    },{
        title: " III категория",
        dataIndex: "three"
    },{
        title: " IV категория",
        dataIndex: "for"
    }
];

const Data=[{
    key:"1",
    usluga:"Легкая абразивная полировка кузова автомобиля",
    one:"10 000 руб",
    two:"11 000 руб",
    three:"12 000 руб",
    for:"13 000 руб"
},{
    key:"2",
    usluga:"Глубокая абразивная полировка\n" +
        "кузова в несколько слоев",
    one:"13 000 руб",
    two:"14 000 руб",
    three:"15 000 руб",
    for:"16 000 руб"
},{
    key:"3",
    usluga:"Химчистка багажника",
    one:"800 руб",
    two:"1 000 руб",
    three:"1 200 руб",
    for:"1 500 руб"
},{
    key:"4",
    usluga:"  Глубокая абразивная полировка +\n" +
        "жидкое стекло Willson 2 слоя (акция)",
    one:"15 000 руб",
    two:"15 000 руб",
    three:"15 000 руб",
    for:"15 000 руб"
},{
    key:"5",
    usluga:"Мягкая восстановительная полировка жидкое стекло Willson 2 слоя (акция)",
    one:"10 000 руб",
    two:"10 000 руб",
    three:"10 000 руб",
    for:"10 000 руб"
}]
const PriceKhim = () => {
    return (
        <div>
            <h1 style={{textAlign:"center",fontSize:"22px",margin:"20px"}}>Полировка кузова автомобиля:</h1>
            <Table pagination={false} scroll={{x:true}} columns={columns} dataSource={Data} />
        </div>

    );
};

export default PriceKhim;