import React, {useState} from 'react';
import "./header.css"
import {
    IconMenu2,
    IconMapPin,
    IconPhone,
    IconBrandWhatsapp,
    IconBrandTelegram,
    IconChevronDown
} from '@tabler/icons-react';
import Nav from "./Nav";
import {NavLink} from "react-router-dom";
import {Breadcrumb} from "antd";

const Header = () => {
    const [nav, setNav] = useState(false)
    const [view, setView] = useState({
        class: "",
        subClass: ""
    })
    return (
        <div className='header'>
            <div className="header__inner">
                <div className="header__items logo">
                    <div className="menu">
                        <IconMenu2 onClick={() => {
                            setNav(true)
                        }}/>
                        <div className="menu__inner">

                        </div>
                    </div>
                   <NavLink to={"/"}>KoxStudio</NavLink>

                </div>
                <div className="header__items info "><b>Доверьте свой автомобиль профессионалам</b></div>
                <div className="header__items">
                    <div className="header__address__inner">
                        <div className="header__address__items">
                            <div className="header__address__inner__icon">
                                <IconMapPin/>
                            </div>
                            Москва, улица Академика Анохина 6к2
                        </div>
                        <div className="header__address__items">
                            Москва, Ильменский проезд 8 стр 3
                        </div>
                    </div>
                </div>
                <div className="header__items">
                    <div className="header__items__phone__inner">
                        <div className="header__items__phone__items">
                            <div className="header__items__phone__items__icon">
                                <IconPhone/>
                            </div>
                            <a href="tel:+79693434888" className="header__items__phone__items__call">
                                +79693434888
                            </a>
                        </div>
                        <div className="header__items__phone__items">
                            Ежедневно: с 9:00 до 23:00
                        </div>
                    </div>
                </div>

                <div className="header__items btn">
                    <div className="header__items__messengers">
                        <a href='tel:+79693434888' className="header__items__messengers__btn"><IconPhone/></a>
                        <a href='https://wa.me/79693434888?text='
                           className="header__items__messengers__btn"><IconBrandWhatsapp/></a>
                        <a href='https://t.me/kohstudio' className="header__items__messengers__btn"><IconBrandTelegram/></a>
                    </div>
                    <button className="header__items__btn">Записаться</button>
                </div>
            </div>
            <div className="nav">
                <div className="nav__inner">
                    <div onMouseMove={() => {
                        setView({
                            class: "usluga",
                            subClass: ""
                        })
                    }}
                         className="nav__items usluga">
                        <NavLink to={"/"}>Услуги</NavLink> <IconChevronDown/>
                        <div onMouseLeave={
                            () => {
                                setView({
                                    class: "",
                                    subClass: ""
                                })
                            }
                        } className={`uslugi ${view.class === "usluga" && "veiw"}`}>
                            <div className="uslugi__inner">
                                <div className="uslugi__items">
                                    <NavLink to="/shinka">Шиномонтаж</NavLink>
                                </div>
                                <div className="uslugi__items">
                                    <NavLink to="/khimka">Химчиста автомобиля</NavLink>
                                </div>
                                <div className="uslugi__items"><NavLink to="/polka">Полировка автомобиля</NavLink>
                                </div>
                                <div className="uslugi__items"><NavLink to ="/zashita">Защитные покрытия</NavLink>
                                </div>
                                <div className="uslugi__items"><NavLink to="/predprodazha">Предпродажная подготовка</NavLink></div>
                                <div className="uslugi__items"><NavLink to='/ozonat'>Озонация салона</NavLink></div>
                            </div>
                        </div>
                    </div>
                    <div
                        onMouseMove={
                            () => {
                                setView({
                                    class: "price",
                                    subClass: ""
                                })
                            }
                        }
                        className="nav__items">
                        <NavLink to="/price">Цены</NavLink> <IconChevronDown/>
                        <div onMouseLeave={() => {
                            setView({
                                class: "",
                                subClass: ""
                            })
                        }} className={`price ${view.class === "price" && "veiw"}`}>
                            <div className="price__inner">
                                <div className="price__items">
                                    <NavLink to={"/price"}>Шиномонтаж</NavLink>
                                </div>
                                <div className="price__items">
                                    <NavLink to={"/price"}>Химчистка/Озонация салона</NavLink>
                                </div>
                                <div className="price__items">
                                    <NavLink to={"/price"}>Полировка/Защита кузова</NavLink>
                                </div>
                                <div className="price__items">
                                    <NavLink to={"/price"}>Постоянным клиентам(скидка)</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nav__items">
                        <a href="">Наши работы</a>
                    </div>
                    <div className="nav__items">
                        <a href="">О компании</a>
                    </div>
                    <div className="nav__items">
                        <a href="">Контакты</a>

                    </div>
                </div>
            </div>
            <div className={`nav__mobile ${nav && "on"}`}>
                <Nav nav={nav} setNav={setNav}/>
            </div>

        </div>
    );
};

export default Header;