import React, {useEffect} from 'react';
import "./shin.css"

const Reblesment = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/3a329f3925b03143518757e0af4142e1.jpg" alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Установка/замена датчиков давления</h3>
                        <p className="shin__text">
                            Двигатель – важный элемент транспортного средства. Но, данный силовой агрегат имеет свое слабое место – систему смазки трущихся деталей. Управляющим органом всего этого выступает датчик давления, который в процессе эксплуатации способен приносить автомобилисту много сюрпризов.

                            Как и любая другая деталь машины, данный элемент требует регулярного обслуживания. Сотрудники автосервиса «Кохстудия» готовы выполнить ремонтные работы любой сложности. Замена датчика давления колеса – одна из приоритетных наших услуг.

                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Когда необходимо обращаться к мастеру?</h3>
                        <p className="shin__text">
                            Исправная работа данного компонента своевременно информирует водителя, что дает возможность безопасно останавливать автомобиль. Ремонт или установка новых датчиков давления может понадобиться, если:
                            <br/><br/>
                            <ul className="shintext__ul">
                                <li>невозможно открутить золотниковую вставку;</li>
                                <li>не получается открутить защитный колпачок золотниковой части;</li>
                                <li>
                                    невозможно выполнить демонтаж компонента.
                                </li>
                            </ul>
                            Наши специалисты в процессе диагностики определят причину неисправности и предложат оптимальный вариант решения проблемы. Полная замена датчиков давления будет выполнена при возникновении проблем с элементами питания.
                            <br/><br/>
                            Необходимо своевременно отслеживать данные признаки. Не нужно при торможении и при движении путать вибрацию. Если присутствуют проблемы с торможением, то причина может заключаться в неисправности тормозных колодок.

                            Схожие симптомы присутствуют и при проблемах с рулевым управлением. При таком раскладе, балансировка не даст желаемого результата. Наши специалисты в ходе диагностики определят причину неисправности и предпримут необходимые действия для устранения проблемы.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Почему выбирают «Кохстудия»?</h3>
                        <p className="shin__text">
                            Установка датчиков давления в шинах выполняется с помощью современного оборудования. В своей работе мы используем универсальные расходные материалы от мировых производителей. Наши сотрудники имеют многолетний опыт работы и соответствующий багаж знаний.

                            Дополнительно мы готовы предложить:
                            <ul className="shintext__ul">
                                <li>гарантию на все предоставленные услуги;</li>
                                <li>профессиональное обслуживание;</li>
                                <li>
                                    целый спектр дополнительных услуг;
                                </li>
                                <li>
                                    профессиональную команду специалистов;
                                </li>
                                <li>
                                    оптимальную стоимость.
                                </li>
                            </ul>
                            Установка данного элемента предотвращает износ кузовных покрышек, позволяет избежать увеличения уровня расхода топлива. Выполнить установку элемента можно и своими руками, но только квалифицированный мастер сумеет выполнить все с учетом существующих стандартов.

                            Цена установки датчиков давления в шинах зависит от стоимости расходных материалов и дополнительных услуг. После завершения работы мастер по вашему желанию сможет изменить базовые настройки на более комфортные. Например, осуществить изменение максимального и минимального показателя или заменить текущие единицы измерения и не только.

                            Чтобы получить детальную консультацию, свяжитесь с нашим менеджером. В телефонном режиме вы сможете договориться об удобном времени. И наши сотрудники заранее сумеют подготовить рекомплект или отдельные элементы для вашей машины.
                        </p>
                    </div>
                </div>
            </div>
            </div>
    );
};

export default Reblesment;