import React from 'react';
import {IconMapPin, IconPhone} from "@tabler/icons-react";
import "./Footer.css"
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <div className='footer'>
            <div className="footer__inner">
                <div className="footer__items">
                    <h3 className="footer__item__title">Компания</h3>
                    <a href="" className="footer__item__link">О компании</a>
                    <a href="" className="footer__item__link">Отзывы</a>
                </div>
                <div className="footer__items">
                    <h3 className="footer__item__title">Услуги</h3>
                    <NavLink to={"/shinka"} className="footer__item__link" >Шиномонтаж</NavLink>
                    <NavLink to="/khimka" className="footer__item__link">Химчиста автомобиля</NavLink>
                    <NavLink className="footer__item__link" to={"/polka"}>Полировка автомобиля</NavLink>
                    <NavLink className="footer__item__link" to={"/zashita"}>Защитные покрытия</NavLink>
                    <NavLink className="footer__item__link" to={"/predprodazha"}>Предпродажная подготовка</NavLink>
                    <NavLink className="footer__item__link" to={"ozonat"}>Озонация салона</NavLink>
                </div>
                <div className="footer__items">
                    <h3 className="footer__item__title">Цены</h3>
                    <NavLink className="footer__item__link" to={"/price"}>Шиномонтаж</NavLink>
                    <NavLink className="footer__item__link" to={"/price"}>Химчиста автомобиля</NavLink>
                    <NavLink className="footer__item__link" to={"/price"}>Полировка автомобиля</NavLink>
                    <NavLink className="footer__item__link" to={"/price"}>Защитные покрытия</NavLink>
                    <NavLink className="footer__item__link" to={"/price"}>Предпродажная подготовка</NavLink>
                    <NavLink className="footer__item__link" to={"/price"}>Озонация салона</NavLink>
                    <NavLink className="footer__item__link" to={"/price"}>Передпроджаная подготока</NavLink>
                </div>
                <div className="footer__items">
                    <h3 className="footer__item__title">Наши контакты</h3>
                    <div className="footer__items__phone__inner">
                        <div className="footer__items__phone__items">
                            <div className="footer__items__phone__items__icon">
                                <IconPhone/>
                            </div>
                            <a className="footer__item__link" href="tel:+79693434888"> +79693434888
                            </a>
                        </div>
                        <div className="footer__items__phone__items footer__item__link">
                            Ежедневно: с 9:00 до 23:00
                        </div>
                    </div>
                    <div className="footer__address__inner">
                        <div className="footer__address__items footer__item__link">
                            <div className="footer__address__inner__icon">
                                <IconMapPin/>
                            </div>
                            Москва, улица Академика Анохина 6к2
                        </div>
                        <div className="footer__address__items footer__item__link">
                            Москва, Ильменский проезд 8 стр 3
                        </div>
                    </div>

                </div>
            </div>
            <div className="copyright">
                © 2023 Все права защищены.
            </div>
        </div>
    );
};

export default Footer;