import React from 'react';
import PriceShin from "./PriceShin";
import "./style.css"
import PriceKhim from "./PriceKhim";
import PricePol from "./PricePol";
import PriceZashita from "./PriceZashita";

const Price = () => {
    return (
        <div className="pricelayout">
            <div className="pricelayout__items">
                <PriceShin/>
            </div>
            <div className="pricelayout__items">
                <PriceKhim/>
            </div><div className="pricelayout__items">
                <PricePol/>
            </div><div className="pricelayout__items">
                <PriceZashita/>
            </div>
            <ul style={{padding:"10px"}}>
                <li>
                    I категория — легковые автомобили мини, малого класса</li>
                   <li>II категория — авто среднего и бизнес класса, кроссоверы</li>
                    <li>III категория —  мультивены и внедорожники</li>
                    <li>IV категория — микроавтобусы и большие внедорожники
                </li>
            </ul>
        </div>
    );
};

export default Price;