import React, {useEffect} from 'react';
import "./Shinka/shin.css"

const Polirovka = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img                              alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Предпродажная подготовка
                            Как подготовить машину к продаже?</h3>
                        <p className="shin__text">
                            Мало кто всерьёз задумывается о целесообразности такой процедуры, как предпродажная подготовка автомобиля, цены на которую колеблются в достаточно большом диапазоне в зависимости от профессионализма сотрудников того или иного автосервиса. Нередко водителям, принявшим решение продавать своё авто, кажется, что любому потенциальному покупателю должна понравиться сама машина, её марка, технические характеристики, а вовсе не её состояние. Тем не менее, важен также и вид автомобиля – то, как он будет выглядеть в глазах постороннего человека. Поэтому перед тем, как предлагать своего «стального коня» будущим владельцам, рекомендуется посмотреть на неё взглядом наблюдателя. Для этого окиньте свой автомобиль внимательным взглядом снаружи, затем загляните в салон и постарайтесь честно ответить на такие вопросы, как:
                            <br/><br/>
                            Полностью ли вас устраивает то, что вы видите?
                            <br/>Что сразу бросается в глаза?
                            <br/>Может быть, вам что-то не нравится?
                            <br/>Хочется ли вам немедленно сесть за руль, завести мотор?
                            <br/> Возникает ли у вас желание приобрести именно эту машину?
                            <br/><br/>
                            Как правило, загрязнённый вид машины, наличие на ней ржавчины и пятен, неприятный запах внутри салона воспринимаются негативно. Для того, чтобы создать у своих клиентов благоприятное впечатление об автомобиле, которые вы предлагаете на продажу, нужны определённые подготовительные меры. В частности, вам помогут сделать предпродажную подготовку, где располагается специализированная компания, предоставляющая услуги подобного рода.

                            Прежде всего, в ходе мероприятий, предшествующих продаже машины, производится мойка корпуса и тщательная очистка салона с использованием специальных средств для химчистки, которые обладают приятных запахом. Чистая машина, внутри царит лёгкий свежий аромат – это первый шаг к успешной продаже. Специалисты также устранят такие дефекты, как наличие царапин, ржавчины и пятен на различных частях машины, проведя тщательную полировку всех поверхностей.

                            Качественно проведённая предпродажная подготовка авто поможет вам осуществить планируемую сделку по достойной цене.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default Polirovka;