import React, {useEffect} from 'react';
import "./uslugi.css"
import {NavLink} from "react-router-dom";

const Uslugi = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    return (
        <div className="uslugibox">
            <div className="uslugibox__inner">
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/925b6b811bc59d24d1c62c6db5138838.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Шиномонтаж</h3>
                        <p className="uslugibox__text"></p>
                        <NavLink to={"/shinka"} className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/fd8bf6665d85009220abc9e180e64779.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Химчистка автомобиля</h3>
                        <p className="uslugibox__text"></p>
                        <NavLink to={"/khimka"} className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/2504d87eaa7a3ef542de4aa86dfc8be5.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Полировка автомобиля</h3>
                        <p className="uslugibox__text"></p>
                        <NavLink to={"/polka"} className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/2864cf31696fe5f677a805ea40d6275e.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Защитные покрытия</h3>
                        <p className="uslugibox__text"></p>
                        <NavLink to={"/zashita"} className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div> <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://aybaz.ru/wp-content/uploads/d/7/3/d738a240c0ed6a8288656c684969c353.jpeg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Предпродажная подготовка</h3>
                        <p className="uslugibox__text"></p>
                        <NavLink to={"/predprodazha"} className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div><div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/3.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Озонация салона автомобиля</h3>
                        <p className="uslugibox__text"></p>
                        <NavLink to={"/ozonat"} className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Uslugi;