import React from 'react';
import {Space, Table, Tag} from "antd";

// @ts-ignore
const columns: ColumnsType<DataType> = [
    {
        title:"Защитные покрытия",
        dataIndex:"usluga"
    },
    {
        title: "I категория",
        dataIndex: "one"
    },
    {
        title: " II категория",
        dataIndex: "two"
    },{
        title: " III категория",
        dataIndex: "three"
    },{
        title: " IV категория",
        dataIndex: "for"
    }
];

const Data=[{
    key:"1",
    usluga:"Мягкая восстановительная полировка\n" +
        "перед нанесением покрытия (акция)",
    one:"5 000 руб",
    two:"5 000 руб",
    three:"5 000 руб",
    for:"5 000 руб"
},{
    key:"1",
    usluga:"Жидкое стекло Willson 2 слоя (акция)",
    one:"5 000 руб",
    two:"5 000 руб",
    three:"5 000 руб",
    for:"5 000 руб"
},{
    key:"2",
    usluga:"Керамика Krytex 9H (2 слоя) жидкое стекло Krytex Omega 8",
    one:"20 000 руб",
    two:"22 000 руб",
    three:"24 000 руб",
    for:"26 000 руб"
},{
    key:"2",
    usluga:"Керамика Krytex 9H (3 слоя) +\n" +
        "жидкое стекло Krytex Omega 8",
    one:"24 000 руб",
    two:"26 000 руб",
    three:"28 000 руб",
    for:"30 000 руб"
},{
    key:"4",
    usluga:" Полировка фар автомобиля (2 фары) (акция)",
    one:"1 000 руб",
    two:"1 000 руб",
    three:"1 000 руб",
    for:"1 000 руб"
}]
const PriceKhim = () => {
    return (
        <div>
            <h1 style={{textAlign:"center",fontSize:"22px",margin:"20px"}}>Защитные покрытия кузова автомобиля:</h1>
            <Table pagination={false} scroll={{x:true}} columns={columns} dataSource={Data} />
        </div>

    );
};

export default PriceKhim;