import React, {useEffect} from 'react';
import "./shin.css"

const Balance = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/cb6067a7cb40467f595b09cb69088502.jpg" alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Балансировка</h3>
                        <p className="shin__text">
                            Своевременная и правильная балансировка колес – это безопасное движение на дороге. Не каждый автомобилист понимает особенности данного процесса, но знает о его важности.

                            В сервисном центре «Кохстудия» работают настоящие профессионалы, которые с помощью современного оборудования готовы выполнить качественное обслуживание колес диаметром от «12» до «23»



                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Когда может понадобиться балансировка?</h3>
                        <p className="shin__text">
                            Зачастую водителю под силу самостоятельно определить проблему, так как она напрямую отображается на поведении машины. Признаки дисбаланса выглядят следующим образом:
                            <br/><br/>
                            появление неравномерного шума колес во время движения;<br/>
                            возникновение вибраций;<br/>
                            появление проблем с управляемостью;<br/>
                            при наборе скорости наблюдается дрожание и вибрация руля.<br/>
                                                        <br/><br/>
                            Необходимо своевременно отслеживать данные признаки. Не нужно при торможении и при движении путать вибрацию. Если присутствуют проблемы с торможением, то причина может заключаться в неисправности тормозных колодок.

                            Схожие симптомы присутствуют и при проблемах с рулевым управлением. При таком раскладе, балансировка не даст желаемого результата. Наши специалисты в ходе диагностики определят причину неисправности и предпримут необходимые действия для устранения проблемы.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Чем опасен дисбаланс?</h3>
                        <p className="shin__text">
                            Балансировка машины обязательная процедура. Возникновение дисбаланса приведет к целому ряду негативных последствий, среди которых:

                            <ul className="shintext__ul">
                                <li>нарушение контакта с дорожным покрытием;</li>
                                <li>увеличенная нагрузка на подвеску, что в дальнейшем будет сопровождаться износом деталей;</li>
                                <li>
                                    рулевые вибрации станут причиной быстрой утомляемости водителя.
                                </li>
                            </ul>
                            Поддерживание оптимального баланса оказывает непосредственное влияние на безопасность во время движения. Перечисленные факторы отображают только часть последствий при дисбалансе. Но, даже этого должно быть достаточно, чтобы понять важность услуги балансировки.
                        </p>
                    </div>
                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Стоимость услуги</h3>
                        <p className="shin__text">
                            Балансировка колес в городе Москва будет выполнена с использованием оборудования немецкой компании Trommelberg. Цена услуги зависит от параметров дисков, типа автомобиля, размера колес и объема выполненной работы. Точную стоимость сообщит специалист после осуществления предварительной оценки.

                            Обращайтесь в шиномонтаж «Acover» балансировка колес будет выполнена с учетом всех стандартов. В ходе диагностики мы проверим вращение колеса на скорости. Это позволит определить дисбаланс, а так же отследить другие неполадки.

                            Мы не рекомендуем выполнять данный процесс самостоятельно. Нарушение технологии может привести к не самым лучшим последствиям. Поэтому, лучше доверить работу специалистом. Помните, что безопасность на дороге – ответственность не только перед самим собой, но и другими участниками движения.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Balance;