import React, {useEffect} from 'react';
import "./Shinka.css"
import "../uslugi.css"
import {NavLink} from "react-router-dom";

const Shinka = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)

    },[])
    return (
        <div className="uslugibox animate__animated animate__fadeInLeft">
            <div className="uslugibox__inner">
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/6202df3da32770b023dcd4a9558ef6f4.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Шиномонтаж</h3>
                        <p className="uslugibox__text">Дорожное покрытие оставляет желать лучшего, и как бы не старался водитель избежать этого, но к услугам автосервиса приходиться прибегать неоднократно. Как минимум дважды в год автовладелец сталкивается с заменой резины – весной и осенью.</p>
                        <NavLink to="shin" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/Screenshot_1.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Хранение шин</h3>
                        <p className="uslugibox__text">Дважды в год автовладелец сталкивается с необходимостью замены резины. При этом сложности могут возникать не только с самой процедурой, но и с поиском места для хранения. Сразу может возникнуть вопрос, а что делать со свободным комплектом?</p>
                        <NavLink to="hranenie" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/cb6067a7cb40467f595b09cb69088502.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Балансировка</h3>
                        <p className="uslugibox__text">
                            Своевременная и правильная балансировка колес – это безопасное движение на дороге. Не каждый автомобилист понимает особенности данного процесса, но знает о его важности.

                        </p>
                        <NavLink to="balance" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
                <div className="uslugibox__items">
                    <div className="uslugibox__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/3a329f3925b03143518757e0af4142e1.jpg" alt=""/>
                    </div>
                    <div className="uslugibox__data">
                        <h3 className="uslugibox__title">Установка/замена датчиков давления</h3>
                        <p className="uslugibox__text">
                            Двигатель – важный элемент транспортного средства. Но, данный силовой агрегат имеет свое слабое место – систему смазки трущихся деталей. Управляющим органом всего этого выступает датчик давления, который в процессе эксплуатации способен приносить автомобилисту много сюрпризов.

                        </p>
                        <NavLink to="reblesment" className="uslugibox__btn">Подробнее</NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shinka;