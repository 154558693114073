import React from 'react';
import './mobilenav.css'
import {IconMapPin, IconPhone, IconX} from "@tabler/icons-react";
import {NavLink} from "react-router-dom";

const Nav = (props: any) => {
    return (
        <div className="mobilenav">
            <div onClick={() => props.setNav(false)} className="close"><IconX/></div>
            <div className="mobilenav__inner">
                <div className="mobilenav__items">
                    <a href="">О компании</a>
                </div>
                <div className="mobilenav__items usluga">
                    <div className='mobilenav__items__text'><p>Услуги</p></div>
                    <div className="mobilenav__items__uslugi">
                        <NavLink to={"/shinka"}>Шиномонтаж</NavLink>
                        <NavLink to={"/khimka"}>Химчистка автомобиля</NavLink>
                        <NavLink to={"/polka"}>Полировка автомобиля</NavLink>
                        <NavLink to={"/zashita"}>Защитные покрытия</NavLink>
                        <NavLink to={"/predprodazha"}>Предпродажная подготовка</NavLink>
                        <NavLink to={"/ozonat"}>Озонация салона</NavLink>
                    </div>
                </div>
                <div className="mobilenav__items usluga">
                    <div className='mobilenav__items__text'><NavLink to={"/price"}>Цены</NavLink></div>
                    <div className="mobilenav__items__uslugi">
                        <NavLink to={"/price"}>Шиномонтаж</NavLink>
                        <NavLink to={"/price"}>Химчистка/Озонация салона</NavLink>
                        <NavLink to={"/price"}>Полировка/Защита кузова</NavLink>
                        <NavLink to={"/price"}>Постоянным клиентам(скидка)</NavLink>
                    </div>
                </div>
                <div className="mobilenav__items">
                    <a href="">Кантакты</a>
                </div>
                <div className="mobilenav__items__icon">
                    <IconPhone/>
                    <a href="tel:+79693434888" className="p">
                        +79693434888
                    </a>
                </div>


                <div className="mobilenav__items__addres">
                    <div className="mobilenav__items__inner">
                        <div className="mobilenav__items_address">
                            <div className="mobilenav__items__icon">
                                <IconMapPin/>
                            </div>
                            Москва, улица Академика Анохина 6к2
                        </div>
                        <div className="mobilenav__items_address">
                            Москва, Ильменский проезд 8 стр 3
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Nav;