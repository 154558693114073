import React, {useEffect} from 'react';
import "../Shinka/shin.css"

const KhimPola = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/3d91eba8439de79ff563a15447f3d40b.jpg"
                             alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Химчистка пола автомобиля</h3>
                        <p className="shin__text">
                            Надлежащий уход за автомобилем не ограничивается только мытьем внешней поверхности. Со
                            временем салон теряет свежесть, и ездить на таком транспортном средстве некомфортно и
                            неприятно. Помимо эстетики необходимо позаботиться и о внутренней части салона, в том числе
                            о чистоте пола. Мастера сервисного центра «Кохстудия» с помощью профессионального оборудования
                            выполнят эффективную чистку автомобиля.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Для чего нужна химчистка</h3>
                        <p className="shin__text">
                            Со временем напольное покрытие машины будет накапливать дорожную пыль, грязь, реагенты,
                            мусор и не только. Это может стать причиной образования стойких загрязнений и возникновения
                            неприятного запаха. Стоит отметить, что подобная среда приведет к образованию аллергенов и
                            бактерий.

                            Химчистка пола автомобиля станет эффективным решением, с помощью которого можно поддерживать
                            чистоту в салоне. Во время процедуры будут удалены легкие загрязнения, мусор и другая грязь,
                            которая имеет свойство скапливаться в зазорах на полу, под ковриками и в обивке.
                            Эффективность будет достигнута за счет демонтажа сидений и других элементов во время чистки.


                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Последовательность процедуры
                        </h3>
                        <p className="shin__text">

                            Профессиональная химчистка пола авто выполняется с использованием соответствующей техники и
                            химических средств. На подготовительном этапе происходит демонтаж сидений, а также
                            вытаскиваются коврики. После этого:

                            наносится специальное моющее средство;
                            для глубокого проникновения средств в ковролин автомобиль оставляют на некоторое время;
                            с помощью специального пылесоса удаляются остатки моющих веществ;
                            салон автомобиля сушат.
                            Стоит отметить, что химчистка пола авто по приемлемой цене выполняется с применением
                            качественных инструментов. Только с их помощью можно достичь максимального эффекта. Так как
                            используются сильнодействующие вещества, не рекомендуется данный вид очистки выполнять
                            настолько часто, как обычную мойку. Как правило, процедура осуществляется по мере
                            загрязнения.

                        </p>
                    </div>
                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Почему необходимо обращаться за помощью к профессионалам?</h3>
                        <p className="shin__text">
                            Безусловно, обратиться за помощью к профессионалам станет лучшим решением. Детейлинг центр
                            «Кохстудия» уже более 8 лет существует на рынке. Наши мастера качественно и профессионально
                            выполнят химчистку пола автомобиля по доступной цене в Москве. Мы учитываем особенности
                            каждого случая и всегда подходим к работе со знанием дела.

                            Цена на услугу химчистка пола автомобиля зависит от сложности загрязнения, объема работ,
                            использованных расходных материалов и других параметров. Более подробную информацию о
                            стоимости вы можете узнать во время консультации. У вас возникли дополнительные вопросы?
                            Оставляйте свою заявку на сайте или свяжитесь с нашим менеджером другим удобным для вас
                            способом.

                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default KhimPola;