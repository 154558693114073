import React, {useEffect} from 'react';
import "../Shinka/shin.css"

const Khim = () => {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    return (
        <div className="shin">
            <div className="shin__inner">
                <div className="shin__items one">
                    <div className="shin__items__img">
                        <img src="https://acover.ru/wp-content/uploads/2022/03/b72d106f76d0f75ebb12e46be8b35429.jpg"
                             alt=""/>
                    </div>
                    <div className="shin___data">
                        <h3 className="shin__title">Химчистка салона автомобиля</h3>
                        <p className="shin__text">
                            Интенсивная эксплуатация автомобиля, курение, транспортировка различных грузов может
                            привести к необходимости выполнить очистку кресел и всего салона машины. Даже при аккуратном
                            пользовании и соответствующем уходе спинка кресла способна покрываться грязью. Со временем
                            все это может превратиться в среду для развития грибков и бактерий. Эффективно со всем этим
                            сумеют справиться только профессионалы.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Когда может понадобиться комплексная химчистка?</h3>
                        <p className="shin__text">
                            Мировые детейлеры советуют обращаться к специалистам не менее 2-3 раза в год в зависимости
                            от активности эксплуатации. Зачастую комплексная чистка автомобиля необходима если:
                            <br/>
                            <br/> машина используется в коммерческих целях;<br/>
                            в салоне часто курят;<br/>
                            был приобретен подержанный автомобиль с большим пробегом;<br/>
                            у автовладельца есть маленькие дети;<br/>
                            было принято решение продавать машину и т. д.<br/>


                            <br/>Часто к химчистке многие относятся как к эстетической процедуре. Но, после выполнения
                            чистки
                            пребывать в машине будет не просто приятнее, но и безопаснее. К тому же после процедуры все
                            системы будут работать намного эффективнее, особенно это касается кондиционера.
                        </p>
                    </div>

                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Какие этапы включает чистка
                        </h3>
                        <p className="shin__text">
                            Полная химчистка автомобиля затрагивает все труднодоступные места. Мастер разбирает салон, поднимает покрытие ковров, убирает накладки. Процедура осуществляется с помощью специализированных пылесосов с разными насадками и не только.<br/>

                            <br/> Выполняется отдельная мойка каждого элемента салона. При этом применяемые средства для кожаного и текстильного салона будут отличаться. В процессе работы используются только гипоаллергенные и антибактериальные средства.<br/>

                            <br/> Комплексная чистка авто может занимать несколько часов. Специалистами будут устранены все виды загрязнений. На завершающем этапе осуществляется обработка поверхностей разными видами полиролей, что придаст дополнительной свежести салону.<br/>
                        </p>
                    </div>
                </div>
                <div className="shin__items">
                    <div className="shin___data">
                        <h3 className="shin__title">Преимущества профессионального комплексного решения</h3>
                        <p className="shin__text">
                            Комплексная химчистка машины и детейлинг поможет избавить от грязи все поверхности. После проведения процедуры исчезнут:<br/>
                            видимые и невидимые пятна;<br/>
                            смолы, деготь и другие смолистые вещества;<br/>
                            шерстяные отложения, волосы людей, волоконная пыль;<br/>
                            жировые пятна.<br/>
                            Полная химчистка автомобиля по доступной цене в городе Москва будет проведена с применением техники от ведущих мировых производителей. В своей работе мы используем только профессиональную химию. Позаботьтесь о своей машине прямо сейчас, заказывайте комплексную чистку в удобное для вас время.<br/>

                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Khim;